<template>
    <div id="createnewasset" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="newAsset"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="asset_createNewAsset"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-md-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                            <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" fill="#000000" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("asset_createNewAsset") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right" data-cy="NewAssetForm">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section kt-margin-b-0">
                                                    <h3 class="kt-section__title">1. {{ $t("asset_assetInfo") }}</h3>
                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_assetName") }} *</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                    </div>
                                                                    <input id="newAssetNameInput" type="text" maxlength="80" class="form-control" :placeholder="$t('asset_inputInfoAssetName')" v-model="vName" />
                                                                </div>
                                                                <div v-if="!$v.vName.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vName.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetName") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_assetUnit") }} *</label>
                                                                <!-- <select id="newAssetUnitSelect2" class="form-control kt-select2">
                                                                    <option></option>
                                                                </select> -->
                                                                <div class="unit">
                                                                    <div style="width: 100%" class="form-unit form-column form-column-field">
                                                                        <input id="newUnitInput" type="text" maxlength="80" @click="onShowListUnit()" readonly="readonly" class="select-dropdown form-control cursorPointer" :placeholder="$t('asset_selectUnit')" v-model="unitInputVal"/>
                                                                        <i class="fa fa-angle-down icon-down"></i>
                                                                        <div class="list-unit">
                                                                            <span class="search">
                                                                                <input type="text" class="form-column form-column-field" v-model="searchUnit">
                                                                            </span>
                                                                            <ul>
                                                                                <li v-for="unit in this.filterSiteAssetUnits" :key="unit.id" @click="onSelectedUnit(unit)" :class="vUnit && vUnit.id == unit.id && 'unit-selected'">
                                                                                    <span v-if="unit.isDefault">{{$t(unit.name)}}</span>
                                                                                    <div v-else style="display: flex; justify-content: space-between; align-items: center;">
                                                                                        <div>{{unit.name}}</div>
                                                                                        <div>
                                                                                            <button type="button" @click="onEditUnit(unit)" class="btn btn-outline-brand btn-sm btn-icon border-0 mr-2" style="height: 1.9rem; width: 1.9rem"><i class="la la-pencil"></i></button>
                                                                                            <button type="button" @click="onDeleteUnit(unit.id)" class="btn btn-outline-danger btn-sm btn-icon border-0" style="height: 1.9rem; width: 1.9rem"><i class="la la-trash"></i></button>    
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div @click="onShowAddNewUnit()" class="btn btn-brand btn-bold btn-md btn-icon-h kt-margin-l-10 cursorPointer">
                                                                        <span style="font-size: 0.9rem;">
                                                                            <i class="flaticon2-plus kt-margin-r-5"></i>
                                                                            {{ $t("asset_addNewAssetUnit") }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div v-if="!$v.vUnit.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetUnit") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_assetFileBrowser") }}</label>
                                                                <div class="custom-file">
                                                                    <input type="file" @change="handleFileSelected" ref="imageAssetFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept="image/gif, image/jpeg, image/png" id="selectAssetImageInputFile" />
                                                                    <label class="custom-file-label textLeft" id="selectAssetImageInputLabel" for="customFile">{{ $t("common_chooseFile") }}</label>
                                                                    <span class="form-text text-muted">{{ $t("site_selectImageHelpText") }}</span>
                                                                </div>
                                                            </div>
                                                            <div v-show="isImageLoaded" class="col-lg-6">
                                                                <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + this.base64Image + ')' }"></div>
                                                                <div class="kt-margin-b-10 kt-margin-t-10">{{ imageName }}</div>
                                                                <div>
                                                                    <button id="newAssetRemoveImageButton" type="button" @click="onRemoveImage" class="btn btn-outline-brand">{{ $t("common_remove") }}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">2. {{ $t("asset_tagHelp") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("tag_tagsList") }}</label>
                                                                <select id="newAssetTagSelect2" class="form-control kt-select2">
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetTag") }}</span>
                                                            </div>
                                                            <div v-if="vShowTagType" class="col-lg-6">
                                                                <div class="kt-option">
                                                                    <span class="kt-option__control">
                                                                        <img :class="vTagTypeItem.imgClass" :src="vTagTypeItem.img" style="max-width:100%;" />
                                                                    </span>
                                                                    <span class="kt-option__label" style="margin-left:20px;">
                                                                        <span class="kt-option__head">
                                                                            <span class="kt-option__title tagLabel">{{ vTagTypeItem.label }}</span>
                                                                            <span class="kt-option__focus">{{ vTagTypeItem.ref }}</span>
                                                                        </span>
                                                                        <span class="kt-option__body">
                                                                            {{ vTagTypeItem.desc }}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="showButtonNewTag()" class="col-lg-6">
                                                                <div>
                                                                    <label><br /></label>
                                                                </div>
                                                                <div>
                                                                    <router-link :to="{ name: 'newTag' }" id="newAsset_createNewTagButton" class="createNewTagButton btn btn-brand btn-bold btn-sm btn-icon-h">
                                                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                                                        {{ $t("tag_createNewTag") }}
                                                                    </router-link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">3. {{ $t("asset_categoryHelp") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("asset_categoriesList") }} *</label>
                                                                <select id="newAssetCategorySelect2" class="form-control kt-select2">
                                                                    <option></option>
                                                                </select>
                                                                <div v-if="!$v.vCategory.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetCategory") }}</span>
                                                            </div>
                                                            <div class="col-lg-6" v-show="vShowSubcategories">
                                                                <label>{{ $t("asset_subcategoriesList") }}</label>
                                                                <select id="newAssetSubcategorySelect2" class="form-control kt-select2">
                                                                    <option></option>
                                                                </select>
                                                                <span class="form-text text-muted">{{ $t("asset_detailInfoAssetSubcategory") }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id="customFieldBloc" v-show="vShowCustomFields">
                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>
                                                        <h3 class="kt-section__title">4. {{ $t("asset_customFieldsInfo") }}</h3>
                                                        <div class="kt-section__body">
                                                            <div id="kt_repeater_customfield">
                                                                <div data-repeater-list="kt_repeater_customfield-list" class="col-lg-12 customFieldListReap">
                                                                    <div data-repeater-item="" class="form-group row align-items-center customFieldItemReap">
                                                                        <div class="col-md-3">
                                                                            <div class="kt-form__group--inline">
                                                                                <div class="kt-form__control">
                                                                                    <input type="text" name="text-name-customfield" class="form-control whiteBackground" disabled="disabled" />
                                                                                    <input type="text" name="text-id-customfield" class="form-control" disabled="disabled" style="display:none;" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="d-md-none kt-margin-b-10"></div>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                            <div class="kt-form__group--inline">
                                                                                <div class="kt-form__control">
                                                                                    <input type="text" name="text-value-customfield" class="form-control" :placeholder="$t('common_enterValue')" style="display:none;" />
                                                                                    <a href=""
                                                                                       target="_blank"
                                                                                       rel="noopener"
                                                                                       style="display:none;"
                                                                                       class="custom-field-url">
                                                                                    </a>
                                                                                    <input type="text" name="text-date-customfield" class="form-control" readonly="" :placeholder="$t('common_selectDate')" style="display:none;" />
                                                                                    <select name="select-enum-customfield" class="form-control kt-select2" style="display:none;">
                                                                                        <option></option>
                                                                                    </select>
                                                                                    <input type="checkbox" name="check-bool-customfield" checked="checked" class="col-md-1" style="display:none;" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="d-md-none kt-margin-b-10"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="vTagId">
                                                        
                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                        <h3 v-if="vShowCustomFields" class="kt-section__title">5. {{ $t("asset_assetStatusTitle") }}</h3>

                                                        <h3 v-else class="kt-section__title">4. {{ $t("asset_assetStatusTitle") }}</h3>

                                                        <div class="kt-section__body">
                                                            <div class="form-group validated kt-margin-b-0">
                                                                <div class="row" style=" margin-top: 20px; margin-left: 0px; ">
                                                                    <div>
                                                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                                                            <label>
                                                                                <input type="checkbox" checked="checked" id="newAssetStatusCheckbox" v-model="statusActive" @change="newAssetStatusChange">
                                                                                <span></span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                    <label style=" margin-top: -3px; margin-left: 5px; " class="col-form-label" id="newAssetStatusLabel">{{ statusMessage }}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <label class="kt-checkbox kt-margin-r-20">
                                                                <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother") }}
                                                                <span></span>
                                                            </label>
                                                            <button id="newAssetCreateButton" @click="onCreateButton" type="button" :disabled="$v.$invalid" class="btn btn-brand kt-margin-r-5">
                                                                {{ $t("common_add") }}
                                                            </button>
                                                            <button id="newAssetCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";
import SiteTitle from "../site/widgets/sitetitle.vue";

export default {
    data() {
        return {
            vUnit: null,
            unitInputVal: null,
            vTagId: null,
            vTagTypeItem: {},
            vCategory: null,
            vSubCategoryId: null,
            vName: "",
            vCategoryId: null,
            vShowTagType: false,
            vShowSubcategories: false,
            vShowCustomFields: false,
            base64Image: "",
            imageName: "",
            imageFile: null,
            isImageLoaded: false,
            createAnother: false,
            statusActive: true,
            statusMessage: i18n.t("asset_assetStatusActive"),
            customFieldsRepeater: null,
            isHandleEditOrDelete: false,
            searchUnit: "",
            listAssetUnits: []
        };
    },
    created: function() {
        console.log("Component(NewAsset)::created() - called");
        this.createAnother = this.createAnotherAsset;
        this.setCreateAnotherAsset(false);
        // Get list of tags (including freeTags)
        this.getSiteTags(this.$route.params.siteId);
        // Get list of categories (siteAssetCategories)
        this.getSiteAssetCategories(this.$route.params.siteId);
        this.getAllAssetUnitsOfSite(this.$route.params.siteId);
    },
    mounted: function() {
        console.log("Component(NewAsset)::mounted() - Init metronic layout");
        KTLayout.init();

        // this.initUnitSelect2();
    },
    destroyed: function() {
        console.log("Component(NewAsset)::destroyed() - called");
        this.resetAssetUnitsState();
    },
    validations: {
        vName: {
            required,
            minLen: minLength(2)
        },
        vUnit: {
            required
        },
        vCategory: {
            required
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        freeTags: function(tags) {
            console.log("Component(NewAsset)::watch(freeTags) - called with ", tags);
            if (tags) {
                // Initialize data for tag select2
                this.initTagSelect2();
            }
        },

        siteAssetCategories: function(siteCategories) {
            if (siteCategories) {
                this.initCategorySelect2();
            }
        },

        vCategoryId: function(value) {
            console.log("Component(NewAsset)::watch(vCategoryId) called");
            if (value === null) {
                // No subcategory to load
                return;
            }
            // Initialize the subcategories and customfields
            let subcategories = [];
            this.vShowSubcategories = this.vShowCustomFields = false;
            let customFields = [];
            if (this.siteAssetCategoriesById[this.vCategoryId]) {
                if (this.siteAssetCategoriesById[this.vCategoryId].subcategories) {
                    subcategories = this.siteAssetCategoriesById[this.vCategoryId].subcategories;
                    this.initSubcategorySelect2(subcategories);
                    this.vShowSubcategories = subcategories.length > 0 ? true : false;
                }
                if (this.siteAssetCategoriesById[this.vCategoryId].customFields) {
                    customFields = this.siteAssetCategoriesById[this.vCategoryId].customFields;
                    this.initCustomFieldRepeater(customFields);
                    this.vShowCustomFields = customFields.length > 0 ? true : false;
                }
            }
        },

        user: function(user) {
            console.log("Component(NewAsset)::watch(user) - called with ", user);
            if (user) {
                // In case of language change
                // Translate icon file browser
                commonVueHelper.resetImageInputLabel(this.isImageLoaded);
                // Translate unit select2
                // this.updateUnitSelect2();
                // Translate tag select2
                this.updateTagSelect2();
                // Translate categories and subcategories select2
                this.updateCategorySelect2();

                this.unitInputVal = this.vUnit ?
                    this.vUnit.isDefault ?
                        i18n.t(this.vUnit.name) :
                        this.vUnit.name
                    : "";
            }
        },

        assetUnits: function(assetUnits) {
            console.log("Component(NewAsset)::watch(assetUnits) - called with ", assetUnits);
            let newAssetUnit = assetUnits.find(unit => {return unit.isNewCreated});

            if (!newAssetUnit) {
                newAssetUnit = assetUnits.find(unit => { return this.vUnit && unit.id ===  this.vUnit.id });
            }

            if (newAssetUnit) {
                this.vUnit = newAssetUnit;

                if (newAssetUnit.isDefault) {
                    this.unitInputVal = i18n.t(newAssetUnit.name);
                } else {
                    this.unitInputVal = newAssetUnit.name;
                }
            }
        }
    },
    computed: {
        ...mapGetters(["freeTags", "siteAssetCategories", "siteAssetCategoriesById", "currentSite", "createAnotherAsset", "user", "assetUnits"]),
        filterSiteAssetUnits() {
            return this.assetUnits.filter(assetUnit => {
                let unitName = assetUnit.isDefault ? i18n.t(assetUnit.name) : assetUnit.name;
                return unitName.toLowerCase().indexOf(this.searchUnit.toLowerCase()) != -1;
            });
        }
    },
    methods: {
        ...mapActions([
            "uploadAssetImage",
            "getSiteTags",
            "getSiteAssetCategories",
            "createAsset",
            "setCreateAnotherAsset",
            "getAllAssetUnitsOfSite",
            "deleteAssetUnitById",
            "resetAssetUnitsState",
            "showAssetUnitModal"
        ]),

        onShowAddNewUnit() {
            console.log("Component(NewAsset)::onShowAddNewUnit() - called");
            this.showAssetUnitModal({type: "CREATE"});
        },

        onSelectedUnit(unit) {
            if(!this.isHandleEditOrDelete) {
                this.vUnit = unit;
                if (unit.isDefault) {
                    this.unitInputVal = i18n.t(unit.name);
                } else {
                    this.unitInputVal = unit.name;
                }
            }
            $(".list-unit").hide();
        },

        onShowListUnit() {
            $("#newUnitInput").on('keydown keypress', function (e) {
                e.preventDefault();
            });
            if ($(".list-unit").css('display') === 'none') {
                $(".list-unit").show();
                this.registerEventUnit();
            } else {
                $(".list-unit").hide();
            }
            if($(".unit-selected")[0]) {
                $(".list-unit ul").animate({
                    scrollTop: $(".unit-selected")[0].offsetTop - 150
                }, 0);
            }
        },

        registerEventUnit() {
            if ($(".list-unit").css('display') === 'block') {
                $("body").click(function (e) {
                    if (
                        (!$(".list-unit").is(e.target) && $(".list-unit").has(e.target).length === 0) &&
                        (!$("#newUnitInput").is(e.target) && $("#newUnitInput").has(e.target).length === 0)
                    ) {
                        $(".list-unit").hide();
                    }
                });
            }
        },

        onEditUnit(unit) {
            this.isHandleEditOrDelete = true;
            this.showAssetUnitModal({type: "UPDATE", data: unit });
            setTimeout(() => {
                this.isHandleEditOrDelete = false;
            }, 100);
        },

        onDeleteUnit(unitId) {
            this.isHandleEditOrDelete = true;
            this.deleteAssetUnitById({ siteId: this.$route.params.siteId, unitId: unitId });
            if (this.vUnit && unitId === this.vUnit.id) {
                this.vUnit = "";
                this.unitInputVal = "";
            }
            setTimeout(() => {
                this.isHandleEditOrDelete = false;
            }, 50);
        },

        getTagDataGroupSelect2() {
            let dataGroup = {};
            if (this.freeTags) {
                // Initialize data for tag select2
                for (let i = 0; i < this.freeTags.length; i++) {
                    let tagTypeName = commonVueHelper.getPropFromTagType("name", this.freeTags[i].tagType); // Can be "ASSET", "CARD", "UNIVERSAL" in tagtype table of CTC database
                    let tagTypeLabel = commonVueHelper.getPropFromTagType("label", this.freeTags[i].tagType);
                    // Group free tags by tag type
                    if (!dataGroup[tagTypeName]) {
                        dataGroup[tagTypeName] = {};
                    }
                    dataGroup[tagTypeName].text = tagTypeLabel;
                    // Add child option by {id, macAdress} in group
                    if (!dataGroup[tagTypeName].children) {
                        dataGroup[tagTypeName].children = [];
                    }
                    let dataOpt = {
                        id: this.freeTags[i].id,
                        text: this.freeTags[i].serialNumber
                    };
                    dataGroup[tagTypeName].children.push(dataOpt);
                }
            }
            // 1. Order group by text (and OTHER key at the end)
            let keysSorted = Object.keys(dataGroup).sort(function(a, b) {
                if (a === "OTHER") {
                    // OTHER to set at the end of keySorted
                    return 1; // a is greater than b
                }
                if (b === "OTHER") {
                    // OTHER to set at the end of keySorted
                    return -1; // a is less than b
                }
                if (dataGroup[a].text < dataGroup[b].text) {
                    return -1; // a is less than b
                }
                return 1; // a is greater than b
            });
            // 2. Order group type by children text
            let dataGroupListSelect2 = [];
            for (let i = 0; i < keysSorted.length; i++) {
                let tagTypeName = keysSorted[i];
                if (dataGroup[tagTypeName]) {
                    // Ascending sort order of datagroup children (by MacAddress)
                    dataGroup[tagTypeName].children = _.orderBy(dataGroup[tagTypeName].children, [child => child.text.toLowerCase()], ["asc"]);
                    dataGroupListSelect2.push(dataGroup[tagTypeName]);
                }
            }
            return dataGroupListSelect2;
        },

        initTagSelect2() {
            var self = this;
            const dataGroupListSelect2 = this.getTagDataGroupSelect2();
            this.vShowTagType = false;
            commonVueHelper.destroySelect2($("#newAssetTagSelect2"));
            $("#newAssetTagSelect2")
                .select2({
                    allowClear: true,
                    placeholder: i18n.t("asset_selectTag"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataGroupListSelect2
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vTagId = $(this).val();
                    self.vShowTagType = false;
                    // Get the tag type item of the selected tag
                    self.vTagTypeItem = {};
                    if (self.vTagId) {
                        // Get the tag type information to display
                        for (let i = 0; i < self.freeTags.length; i++) {
                            if (self.vTagId === self.freeTags[i].id) {
                                self.vTagTypeItem = commonVueHelper.getTagTypeItem(self.freeTags[i].tagType);
                                break;
                            }
                        }
                    }
                    // Show the tag info if the selected tag type is defined (and not other)
                    if (self.vTagTypeItem.name) {
                        self.vShowTagType = true;
                    }
                });
            // If a tag id has been passed, auto select the dedicated tag into the list
            if (this.$route.params.tagId) {
                $("#newAssetTagSelect2").val(this.$route.params.tagId);
                $("#newAssetTagSelect2").trigger({ type: "change", params: { initialInit: true } });
            }
        },

        updateTagSelect2() {
            // Get data of tag select2
            const dataGroupListSelect2 = this.getTagDataGroupSelect2();
            // Save selected tag
            const currentValue = $("#newAssetTagSelect2").val();
            // Update tag select2
            $("#newAssetTagSelect2")
                .empty()
                .select2({
                    placeholder: i18n.t("asset_selectTag"),
                    data: dataGroupListSelect2
                })
                .val(currentValue)
                .trigger("change");
        },

        initUnitSelect2() {
            var self = this;
            // Init unit Select2
            commonVueHelper.destroySelect2($("#newAssetUnitSelect2"));
            $("#newAssetUnitSelect2")
                .select2({
                    placeholder: i18n.t("asset_selectUnit"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: commonVueHelper.getUnitsData()
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vUnit = $(this).val();
                });
        },


        updateUnitSelect2() {
            const currentValue = this.vUnit;
            $("#newAssetUnitSelect2")
                .empty()
                .select2( {
                    placeholder: i18n.t("asset_selectUnit"),
                    data: commonVueHelper.getUnitsData()
                })
                .val(currentValue)
                .trigger("change");
        },

        formatCategory(categoryItem) {
            if (!categoryItem.id) {
                return categoryItem.text;
            }
            let formatCat;
            if (categoryItem.isDefault) {
                formatCat = "<img class='flag' style='width:30px;margin-right:5px' src='/assets/categories/" + categoryItem.categoryName + "/Icon.png'/>";
            } else {
                formatCat = "<img class='iconPreviewCustomCat' style='background-image:url(" + categoryItem.image + ");margin-right:5px' />";
            }
            formatCat += "<span>" + categoryItem.text + "</span>";
            return $(formatCat);
        },

        getDataCategorySelect2() {
            let dataCategorySelect2 = [];
            for (let i = 0; i < this.siteAssetCategories.length; i++) {
                let categoryName = this.siteAssetCategories[i].name;
                let isDefault = this.siteAssetCategories[i].isDefault;
                let image = this.siteAssetCategories[i].iconImg;
                let categoryNameTranslated;
                if (this.siteAssetCategories[i].isDefault) {
                    categoryNameTranslated = i18n.t(categoryName);
                } else {
                    categoryNameTranslated = categoryName;
                }
                dataCategorySelect2.push({ id: this.siteAssetCategories[i].id, text: categoryNameTranslated, categoryNameTranslated, categoryName: categoryName, isDefault: isDefault, image: image });
            }
            // Ascending sort order Select2 data by text
            dataCategorySelect2 = _.orderBy(dataCategorySelect2, [category => category.text.toLowerCase()], ["asc"]);

            return dataCategorySelect2;
        },

        initCategorySelect2() {
            var self = this;
            // Init Select2 data of categories
            let dataCategorySelect2 = this.getDataCategorySelect2();
            // Init categories Select2
            commonVueHelper.destroySelect2($("#newAssetCategorySelect2"));

            $("#newAssetCategorySelect2")
                .select2({
                    placeholder: i18n.t("asset_selectCategory"),
                    width: "100%",
                    data: dataCategorySelect2,
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    escapeMarkup: function(m) {
                        return m;
                    },
                    templateResult: self.formatCategory,
                    templateSelection: self.formatCategory
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    // Store category index to retrieve associated subcategory
                    self.vCategoryId = $(this).val();
                    self.vCategory = $(this).val();
                });
        },

        updateCategorySelect2() {
            var self = this;
            // Init Select2 data of categories
            let dataCategorySelect2 = this.getDataCategorySelect2();
            // Save selected category
            const currentValue = $("#newAssetCategorySelect2").val();
            // Update categories Select2
            $("#newAssetCategorySelect2")
                .empty()
                .select2({
                    placeholder: i18n.t("asset_selectCategory"),
                    data: dataCategorySelect2,
                    templateResult: self.formatCategory,
                    templateSelection: self.formatCategory
                })
                .val(currentValue)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    // Store category index to retrieve associated subcategory
                    self.vCategoryId = $(this).val();
                    self.vCategory = $(this).val();
                });
            // Update attached subcategories
            if (currentValue) {
                const subcategories = this.siteAssetCategoriesById[currentValue].subcategories;
                if (subcategories) {
                    this.updateSubcategorySelect2(subcategories);
                }
            }
        },

        initSubcategorySelect2(subcategories) {
            var self = this;
            // Init Select2 data of subcategories
            let dataSubcategorySelect2 = $.map(subcategories, function(subcategory) {
                let subcategoryName = subcategory.name;
                if (subcategory.isDefault && subcategory.name) {
                    subcategoryName = i18n.t(subcategory.name);
                }
                subcategory.text = subcategory.text || subcategoryName;
                return subcategory;
            });
            // Ascending sort order Select2 data by text
            dataSubcategorySelect2 = _.orderBy(dataSubcategorySelect2, [subcategory => subcategory.text.toLowerCase()], ["asc"]);
            // Init subcategories Select2
            commonVueHelper.destroySelect2($("#newAssetSubcategorySelect2"));
            $("#newAssetSubcategorySelect2")
                .select2({
                    placeholder: i18n.t("asset_selectSubcategory"),
                    data: dataSubcategorySelect2,
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    width: "100%"
                })
                .val(null)
                .trigger("change")
                .on("change", function() {
                    self.vSubCategoryId = $(this).val();
                });
        },

        updateSubcategorySelect2(subcategories) {
            var self = this;
            // Init Select2 data of subcategories
            let dataSubcategorySelect2 = $.map(subcategories, function(subcategory) {
                if (subcategory.isDefault && subcategory.name) {
                    subcategory.text = i18n.t(subcategory.name) || subcategory.text;
                } else {
                    subcategory.text = subcategory.text || subcategory.name;
                }
                return subcategory;
            });
            // Ascending sort order Select2 data by text
            dataSubcategorySelect2 = _.orderBy(dataSubcategorySelect2, [subcategory => subcategory.text.toLowerCase()], ["asc"]);
            // Save selected subcategory
            const currentValue = $("#newAssetSubcategorySelect2").val();
            // Update subcategories Select2
            $("#newAssetSubcategorySelect2")
                .empty()
                .select2({
                    placeholder: i18n.t("asset_selectSubcategory"),
                    data: dataSubcategorySelect2
                })
                .val(currentValue)
                .trigger("change");
        },

        initRepeaterItem() {
            let str_enterValue = i18n.t("common_enterValues");
            let str_selectDate = i18n.t("common_selectDate");
            let htmlToInsert = '\
                <div data-repeater-item="" class="form-group row align-items-center customFieldItemReap">\
                    <div class="col-md-3">\
                        <div class="kt-form__group--inline">\
                            <div class="kt-form__control">\
                                <input type="text" name="text-name-customfield" class="form-control" disabled="disabled" style="background:white;border:none;padding-left:0px"/>\
                                <input type="text" name="text-id-customfield" class="form-control" disabled="disabled" style="display:none;" />\
                            </div>\
                        </div>\
                        <div class="d-md-none kt-margin-b-10"/>\
                    </div>\
                    <div class="col-md-3">\
                        <div class="kt-form__group--inline">\
                            <div class="kt-form__control">\
                                <a href="" target="_blank" rel="noopener" style="display:none;" class="custom-field-url"></a>\
                                <input type="text" name="text-value-customfield" class="form-control" :placeholder="'+str_enterValue+'" style="display:none;" />\
                                <input type="text" name="text-date-customfield" class="form-control" readonly="" :placeholder="'+str_selectDate+'" style="display:none;" />\
                                <select name="select-enum-customfield" class="form-control kt-select2" style="display:none;">\
                                    <option/>\
                                </select>\
                                <input type="checkbox" name="check-bool-customfield" checked="checked" class="col-md-1" style="display:none;" />\
                            </div>\
                        </div>\
                        <div class="d-md-none kt-margin-b-10"/>\
                    </div>\
                </div>';
            $(".customFieldItemReap").remove();
            $(".customFieldListReap").append(htmlToInsert);
        },

        initCustomFieldRepeater(customFields) {

            // Set the values of tcustom fields by id
            let customFieldsById = {};
            for (let n = 0; n < customFields.length; n++) {
                let item = customFields[n];
                customFieldsById[item.id] = item;
            }
            // Hide all previous input repeaters (for which name end with [text-xxx-customfield])
            $("#kt_repeater_customfield")
                .find("input[name$='" + "[text-value-customfield]" + "']")
                .hide();
            $("#kt_repeater_customfield")
                .find("input[name$='" + "[text-date-customfield]" + "']")
                .hide();
            $("#kt_repeater_customfield")
                .find("input[name*='" + "[check-bool-customfield]" + "']")
                .hide();
            let enumSelectors = $("#kt_repeater_customfield").find("select[name$='" + "[select-enum-customfield]" + "']");
            for (let k = 0; k < enumSelectors.length; k++) {
                $(enumSelectors[k]).hide();
                commonVueHelper.destroySelect2($(enumSelectors[k]));
                //$(enumSelectors[k]).next(".select2-container").remove();
                //$(enumSelectors[k]).remove();
            }

            if (this.customFieldsRepeater) {
                // To remove all previous custom field items
                this.initRepeaterItem();
                this.customFieldsRepeater = null;
            }

            // Setup the repeater for custom fields
            this.customFieldsRepeater = $("#kt_repeater_customfield").repeater({
                initEmpty: true,
                show: function() {
                    let currentVal = $(this).repeaterVal();
                    let id = currentVal["kt_repeater_customfield-list"][0]["text-id-customfield"];
                    // Get jquery selector of each custom field value
                    let selector = null;
                    switch (customFieldsById[id].typec) {
                        case "Date":
                            selector = $(this).find("input[name$='" + "[text-date-customfield]" + "']");
                            commonVueHelper.initDatePicker(selector);
                            break;
                        case "Enum":
                            selector = $(this).find("select[name$='" + "[select-enum-customfield]" + "']");
                            commonVueHelper.initSelect2CustomField(selector, customFieldsById[id]);
                            break;
                        case "Bool":
                            selector = $(this).find("input[name*='" + "[check-bool-customfield]" + "']");
                            break;
                        case "URL":
                            selector = $(this).find("a[class=custom-field-url]");
                            commonVueHelper.setURLVal(selector, customFieldsById[id].staticValue);
                            break;
                        default:
                            selector = $(this).find("input[name$='" + "[text-value-customfield]" + "']");
                    }
                    selector.show();
                    $(this).slideDown();
                },
                hide: function (deleteElement) {
                    console.log("hide");
                },
                ready: function (setIndexes) {
                    console.log("ready setIndexes: ", setIndexes);
                }
            });
            // Set the values of the inputs as a formatted object
            let textInputCustomFieldList = [];
            for (let i = 0; i < customFields.length; i++) {
                let customfieldName = customFields[i].name;
                if (customFields[i].isDefault && customfieldName) {
                    customfieldName = i18n.t(customfieldName);
                }
                textInputCustomFieldList.push({ "text-name-customfield": customfieldName, "text-id-customfield": customFields[i].id });
            }
            this.customFieldsRepeater.setList(textInputCustomFieldList);
        },

        // Function called when user select an image
        handleFileSelected() {
            console.log("Component(NewAsset)::handleFileSelected() - called");
            if (this.$refs.imageAssetFile.files.length > 0) {
                let imgSelectedFile = this.$refs.imageAssetFile.files[0];
                if (this.$refs.imageAssetFile.files[0].size > 3145728) {
                    // 3 MB max
                    alert(i18n.t("common_fileTooBig", { fileLimit: "3" }));
                    this.base64Image = "";
                    this.imageName = "";
                    this.isImageLoaded = false;
                    this.imageFile = null;
                } else {
                    this.imageFile = imgSelectedFile;
                    this.imageName = this.imageFile.name;
                    var reader = new FileReader();
                    reader.readAsDataURL(this.imageFile);
                    reader.onload = () => {
                        this.base64Image = reader.result;
                        this.isImageLoaded = true;
                    };
                }
            }
        },

        // Trigger when user change the active checkbox
        newAssetStatusChange() {
            if (this.statusActive) {
                this.statusMessage = i18n.t("asset_assetStatusActive");
            } else {
                this.statusMessage = i18n.t("asset_assetStatusInactive");
            }
        },

        // Function called when user click on the "Remove" button to remove image
        onRemoveImage() {
            this.base64Image = "";
            this.imageName = "";
            this.imageFile = null;
            this.isImageLoaded = false;
            // Force clear of input file to be able to select the same image again
            $("#selectAssetImageInputFile").val(null);
            // Replace removed file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(NewAsset)::onCancel() - called");
            this.$router.push({ name: "assets", params: { fromAction: "cancelButton" } });
        },

        // Function called when user click on the "Create site" button
        onCreateButton() {
            console.log("Component(NewAsset)::onCreateButton() - called");

            // Initialize data of the asset create
            let assetData = {
                siteId: this.$route.params.siteId,
                name: this.vName,
                assetUnitId: this.vUnit.id,
                createAnother: this.createAnother
            };

            if (this.imageName) {
                assetData.imageName = this.imageName;
            }

            if (this.base64Image) {
                assetData.image = this.base64Image;
            }

            if (this.vTagId) {
                assetData.tagId = this.vTagId;
                assetData.isEnabled = this.statusActive;
            } else {
                assetData.tagId = null;
                assetData.isEnabled = false;
            }

            if (this.vCategoryId) {
                assetData.assetCategoryId = this.vCategoryId;
            }

            if (this.vSubCategoryId) {
                assetData.assetSubcategoryId = this.vSubCategoryId;
            }

            if (this.vShowCustomFields) {
                // Get the values of the custom fields repeater
                let repeaterCustomFieldsVal = $("#kt_repeater_customfield").repeaterVal();
                if (repeaterCustomFieldsVal && repeaterCustomFieldsVal["kt_repeater_customfield-list"] && repeaterCustomFieldsVal["kt_repeater_customfield-list"].length > 0) {
                    assetData.customfields = [];
                    for (let i = 0; i < repeaterCustomFieldsVal["kt_repeater_customfield-list"].length; i++) {
                        let repeaterItem = repeaterCustomFieldsVal["kt_repeater_customfield-list"][i];
                        let repeaterValue = "";
                        for (let prop in repeaterItem) {
                            if (repeaterItem.hasOwnProperty(prop)){
                                let nameSelector = "kt_repeater_customfield-list[" + i + "][" + prop + "]";
                                let isDisplayed = false;
                                let selector;
                                switch (prop) {
                                    case "text-value-customfield":
                                        isDisplayed = $("#kt_repeater_customfield")
                                            .find("input[name='" + nameSelector + "']")
                                            .is(":visible");
                                        if (isDisplayed && repeaterItem[prop]) {
                                            repeaterValue = repeaterItem[prop];
                                        }
                                        break;
                                    case "select-enum-customfield":
                                        isDisplayed = $("#kt_repeater_customfield")
                                            .find("select[name='" + nameSelector + "']")
                                            .is(":visible");
                                        if (isDisplayed && repeaterItem[prop]) {
                                            repeaterValue = repeaterItem[prop];
                                        }
                                        break;
                                    case "text-date-customfield":
                                        selector = $("#kt_repeater_customfield").find("input[name='" + nameSelector + "']");
                                        isDisplayed = selector.is(":visible");
                                        if (isDisplayed && repeaterItem[prop]) {
                                            repeaterValue = commonVueHelper.getDatePickerISOString(selector);
                                        }
                                        break;
                                    case "check-bool-customfield":
                                        isDisplayed = $("#kt_repeater_customfield")
                                            .find("input[name^='" + nameSelector + "']")
                                            .is(":visible");
                                        if (isDisplayed && repeaterItem[prop]) {
                                            if (repeaterItem[prop].length === 1) {
                                                repeaterValue = "true";
                                            } else {
                                                repeaterValue = "false";
                                            }
                                        }
                                        break;
                                }
                            }
                            if (repeaterValue) {
                                break;
                            }
                        }
                        assetData.customfields.push({ id: repeaterItem["text-id-customfield"], value: repeaterValue });
                    }
                }
            } else {
                // Select category has no custom fields
                assetData.customFields = [];
            }

            // Create the new asset in the backend directly as there is no image for the asset
            this.createAsset(assetData);
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (this.vName || this.base64Image || this.imageName || this.vTagId || Number.isInteger(this.vCategoryId)) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        },

        showButtonNewTag() {
            // No tag selected
            if (!this.vTagId) {
                // No free tags
                if (!this.freeTags) {
                    return true;
                }
                if (this.freeTags.length === 0) {
                    return true;
                }
            }
            return false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },
    filters: {},

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "NewAsset", "create", !this.isEmptyForm(), to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.textLeft {
    text-align: left !important;
}
.imagePreview {
    height: 108px;
    width: 160px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #ebedf2;
}
.createNewTagButton {
    height: 36px;
    padding-top: 9px;
    margin-left: -10px !important;
}
.whiteBackground {
    background: white;
    border: none;
    padding-left: 0px;
}
.customFieldListReap {
    margin-top: 30px;
    margin-left: -10px;
}
.boldBorder {
    border: 1px solid #d2d5dc;
}
.tagColorTxt {
    color: #2d2929 !important;
}
.unit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}
.form-unit {
    position: relative;
}
.icon-down {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #b3b7c0;
}
.list-unit {
    width: 100%;
    position: absolute;
    top: 100%;
    background: white;
    border: 1px solid #d2d5dc;
    z-index: 89;
    cursor: pointer;
    display: none;
}
.list-unit span.search {
    padding: 15px;
    display: block;
}
.list-unit span.search input {
    width: 100%;
    border: 1px solid #ced0d6;
    border-radius: 4px;
    height: 30px;
    outline: none !important;
}
.list-unit ul {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 0px;
}
.list-unit ul li {
    padding: 5px 15px;
}
.list-unit ul li:hover {
    background: #E1E1E4;
}
.unit-selected {
    background: #E1E1E4;
}
</style>
